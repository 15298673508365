* {
  font-family: "Ubuntu", sans-serif;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Bold.eot');
  src: url('./assets/fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Gotham-Bold.woff2') format('woff2'),
      url('./assets/fonts/Gotham-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('./assets/fonts/Gotham-Book.eot');
  src: url('./assets/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Gotham-Book.woff2') format('woff2'),
      url('./assets/fonts/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-LightItalic.eot');
  src: url('./assets/fonts/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Gotham-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Gotham-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Light.eot');
  src: url('./assets/fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Gotham-Light.woff2') format('woff2'),
      url('./assets/fonts/Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}





.headerPadding {
  padding-top: 160px !important;
}


.mail-msg {
  position: relative;
  //display: inline-block;
  //margin-top: 66px;
  font-size: 17px !important;
  text-align: center;
}

html {
  // scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Ubuntu", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
  padding-top: 148px;
  overflow-x: auto !important;
}

header {
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15) !important;
  // overflow: hidden;
}

.content-container {
  min-height: 500px;
}

.desabled {
  opacity: 0.5;
  pointer-events: none;
}

.balloon-btn {
  font-size: "21px";
  color: "#000000";
}

.link {
  text-decoration: none;

}

.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.d-none {
  display: none;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.img-responsive {
  max-width: 100%;
  display: block;
  
}

.img-responsive-quadra {
  max-width: 100%;
  display: block;
  height: 100%;
}

.img-responsive-automation {
  max-width: 100%;
  display: block;
  height: 80%;
}

.img-responsive-core-automation {
  max-width: 100%;
  display: block;
  height: 90%;
}
.quadra-span-tag {
  font-family: Gotham !important;
  font-weight: bold;
}


.MuiCheckbox-root {
  color: #29a7df !important;
}

.Mui-checked {
  color: #29a7df !important;
}

.text-italic {
  font-style: italic !important;
}

.text-link {
  color: #29a7df !important;
}

.revers {
  flex-direction: row-reverse !important;
}

.section {
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 250ms ease;
}

/*header start*/

.video-wrapper {
  width: 683px;
  height: 429px;
  max-width: 100%;
}


.logo {
  position: relative;
  margin-right: 20px !important;

  &::before{
    content: '';
    position: absolute;
    background-image: url("../src/assets/images/logo_graphic.png");
    width: 100%;
    height: 150px;
    bottom: 0px;
    // bottom: -50px;
    right: -110px;
    background-size: 400px;
    z-index: -1;
    // overflow: hidden;
  }

  img {
    min-width: 320px;
    width: 320px;
    max-width: 100%;
  }
}


.header-navlinks a {
  font-size: 16px;
  white-space: nowrap;
  color: #222;
  transition: all 250ms ease;
  position: relative;

  &:hover {
    color: #29a7df;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    background-color: #29a7df;
    opacity: 0;
    margin: 0 auto;
  }

  &.active {
    &::before {
      opacity: 1;
    }
  }
}

.main-navs {
  >li {
    position: relative;

    span {
      font-weight: 500;
      text-transform: uppercase;
      font-size: 19px;
      white-space: nowrap;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #29a7df;
      opacity: 0;
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }

    &:hover {
      .dropdown-menu {
        display: flex;
      }
      .dropdown-menu-engineered{
        display: flex;
      }
    }
  }
}

.left-div, .right-div {
  flex: 1;
}

.dropdown-menu-engineered {
  display: none;
  position: absolute !important;
  background-color: #fff;
  top: 100%;
  border-top: 4px solid #29a7df;
  padding-bottom: 0 !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  margin-top: 2px !important;
  min-width: 100%; 
  left: 50%;
  transform: translateX(-50%);
  li {
    padding: 0;

    a {
      color: #000;
      text-decoration: none;
      display: block;
      width: 100% !important;
      padding: 10px 16px !important;
      font-size: 16px;
      text-transform: capitalize !important;
      box-sizing: border-box;

      &:hover {
        background-color: rgba(41, 167, 223, 0.8);
        color: #fff;
        font-weight: 700;
        // width:85% !important;
      }
    }

    &.active {
      a {
        border-top: 4px solid #29a7df;
        color: #fff;
      }
    }
  }
}

.dropdown-menu {
  display: none;
  position: absolute !important;
  //min-width: 200px;
 flex-direction: column;
  background-color: #fff;
  top: 100%;
  border-top: 4px solid #29a7df;
  padding-bottom: 0 !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  margin-top: 2px !important;
  min-width: 100%; 
  right: 0%;

  li {
    padding: 0;

    a {
      color: #000;
      text-decoration: none;
      display: block;
      width: 100% !important;
      padding: 10px 16px !important;
      font-size: 16px;
      text-transform: capitalize !important;
      box-sizing: border-box;

      &:hover {
        background-color: rgba(41, 167, 223, 0.8);
        color: #fff;
        font-weight: 700;
        // width:85% !important;
      }
    }

    &.active {
      a {
        border-top: 4px solid #29a7df;
        color: #fff;
      }
    }
  }
}

.bg-submenu {
  // background-color:#E8E8E8!important;
  // margin-left: 35px !important;
  // margin-right: 5px !important;

  width: auto !important;

  &:hover {
    // background-color: white;

    color: #fff;
    width: auto !important;
  }
}

.mobile-submenu {

  width: auto !important;

  &:hover {


    color: #fff;
    width: auto !important;
  }
}

.mobile-L4submenu {
  margin-left: 20px !important;
  width: auto !important;

  &:hover {


    color: #fff;
    width: auto !important;
  }
}


.about-sub-menu {
  display: none;
  position: absolute !important;
  //min-width: 200px;
  flex-direction: column !important;
  background-color: #fff !important;
  top: 100%;
  border-top: 4px solid #29a7df;
  padding-bottom: 0 !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15) !important;
  margin-top: 2px !important;
  min-width: 100%;

  li {
    padding: 0;

    a {
      color: #000;
      text-decoration: none;
      display: block;
      width: 100%;
      padding: 10px 16px !important;
      font-size: 16px;
      text-transform: capitalize !important;

      &:hover {
        background-color: rgba(41, 167, 223, 0.8);
        color: #fff;
        font-weight: 700;
      }
    }

    &.active {
      a {
        border-top: 4px solid #29a7df;
        color: #fff;
      }
    }
  }
}


.search-icon svg {
  width: 20px;
  color: #9a9a9a;
}

/*header end*/

.hero-section {
  min-height: 979px;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  padding-top: 154px;
  overflow: hidden;

  .slide-count {
    display: inline-flex;
    position: relative;
    align-items: center;
    margin-bottom: 12px;
    margin-left: 7px;

    &::after {
      content: "";
      position: absolute;
      width: 70px;
      height: 4px;
      left: 50px;
      background-color: #29a7df;
    }
  }

  img {
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
  }

  p {
    max-width: 585px;
    font-size: 20px;
    margin-top: 15px;
  }
}

.bullet-indicators {
  position: absolute;
  bottom: 200px;
  z-index: 99;

  button {
    border-radius: 0 !important;
    width: 200px;
    height: 10px;
    background-color: #fff !important;
    margin-left: 15px;
    margin-right: 15px;

    svg {
      display: none;
    }

    &.active-indicator {
      background-color: #ffb801 !important;
    }
  }
}

.btn-white[class] {
  background-color: #ffffff !important;
  color: #071134 !important;
}
.btn-orange[class] {
  background-color: #ffffff !important;
  color: #ff7e24 !important;
  font-size: 30px;
  padding: 18px 40px;
  box-sizing: border-box;
  height: auto;
  white-space: nowrap;
  display: inline-block;
  margin-right: 174px;
 
}
.quadra-btn-yellow[class] {
  width: auto !important; 
}

.btn-dark {
  border-color: #202020 !important;
  color: #202020 !important;
}

.btn-blue[class] {
  background-color: #29a6de !important;
  color: #fff !important;
  // position: absolute;
}

.btn-grey {
  background-color: #f5f5f5;
  color: #fff !important;
}

.bg-gray {
  background-color: #f5f5f5;
}

.card {
  box-shadow: none !important;
  border-radius: 0 !important;

  .MuiCardContent-root {
    padding: 0px !important;
  }

  .btn-link {
    text-decoration: none;
    color: #4a4948;
    font-size: 21px;
    margin-top: 22px;
    display: inline-block;

    &:hover {
      color: #29a7df;
      text-decoration: underline;
    }

    img {
      margin-left: 10px;
    }
  }
}

.news-resources {
  h6 {
    height: 80px;
  }
}

.MuiButton-root {
  text-transform: uppercase !important;
  font-weight: 600 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  height: 48px;
  font-size: 18px;
}

.e2e-capablities {
  z-index: 1;
  position: relative;
  top: -50px;

  img {
    display: block;
    height: 100%;
    object-fit: cover;
    object-position: right;
  }

  .content {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #2dabe2;
    color: #fff;
    position: relative;
    padding-right: 60px;

    &::after {
      content: "";
      background-image: url("assets/images/e2e-icon.png");
      position: absolute;
      top: 22px;
      right: 24px;
      width: 95px;
      height: 95px;
      background-size: 100%;
      background-repeat: no-repeat;
    }

    h4 {
      margin-bottom: 30px !important;
      margin-top: 0;

      * {
        font-size: 40px;
      }
    }

    p {
      font-size: 25px;
    }
  }

  .MuiGrid-container {
    position: relative;

    .white-box {
      position: absolute;
      top: -70px;
      left: -70px;
      background-color: #fff;
      height: 100%;
      width: calc(100% + 140px);
      z-index: -1;
    }
  }

  &.building-strategic {
    .content {
      width: calc(100% + 40px);

      &::after {
        background-image: url("assets/images/bsp-icon.png");
      }
    }
  }
}

.blue-bar {
  height: 24px;
  background: linear-gradient(270deg, #2c65ba 0%, #2a8cc6 54.01%, #2c65ba 100%);
  margin-top: 10px;
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -36px;
    background-color: #c5c5c5;
  }
}

.section-heading {
  position: relative;
  padding-top: 30px;
  font-size: 36px !important;
  margin-bottom: 15px !important;

  &::before {
    content: "";
    // background-color: #29a7df;
    background-color: var(--bullet-bg-color);
    width: 70px;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.section-heading-expert {
  // position: relative;
  // padding-top: 0px;
  // font-size: 30px !important;
  // text-align: left !important;
  // font-weight: 400;

  position: relative;
  // padding-top: 5px;
  text-align: left !important;
  font-weight: 400;
  font-size: 30px !important;
  margin-left: 174px !important;
  // margin-top: 10px !important;
  // &::before {
  //   content: "";
  //   width: 70px;
  //   height: 10px;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
}


.expert-class{
text-align: left;
align-items: center;
width:100%;
// max-width: 1930px;
margin: 0 auto;
display: flex;
gap: 6px;
justify-content: space-between;
// margin-left: 80px;
}

.creating-value {
  background: radial-gradient(50% 50% at 50% 50%, #0054a6 0%, #003b7d 100%);
  position: relative;

  >div {
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url("assets/images/creative-value-design.png");
    background-size: 88%;
    background-position: bottom right;
    background-repeat: no-repeat;
  }

  h6 {
    font-size: 26px;
    font-weight: 700;
  }

  .section-heading {
    &::before {
      background-color: #fff;
    }
  }

  .creating-value-box {
    background-color: #f5f7fa;

    h4 {
      color: #29a7df;
      text-transform: uppercase;
    }

    p {
      padding-right: 85px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);

      h4 {
        color: #013d7f;
      }
    }
  }
}

.news-and-resource {
  img {
    max-width: 90%;
    width: 484px;
  }

  .section-heading {
    &::before {
      background-color: #29a7df;
    }
  }

  .news-box {
    background-color: #fff;
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.16);
    border-radius: 11px;
    height: 480px;
    box-sizing: border-box;
    flex-grow: 1;
  }

  .MuiButton-root {
    //border-radius: 4px !important; ##
    height: 46px;
    min-width: 190px !important;
  }

  li {
    align-items: unset;
    padding-bottom: 40px;
    padding-top: 0;

    &:last-child {
      padding-bottom: 0;
    }

    div {
      margin-top: 0;
      margin-bottom: 0;
    }

    >div:first-child {
      max-width: 222px;

      img {
        min-height: 100%;
        object-fit: cover;
      }
    }

    img {
      max-width: 100%;
    }
  }

  .news-blog {
    h4 {
      max-width: 300px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    button {
      min-width: 190px !important;
    }
  }
}

.creative-solutions {
  background-size: cover;
  background-repeat: no-repeat;
  height: 630px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;

  p {
    // max-width: 806px;
    text-shadow: 3px 10px 10px #000000;
  }
}

.footer {
  margin: -50px 50px 0;

  a {
    color: #4a4948;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: #29a7df;
      text-decoration: underline;
    }
  }

  .newsletter {
    label {
      font-weight: 500;
      color: #4a4948 !important;
    }

    .MuiInput-root::after {
      border-color: #000000 !important;
    }
  }

  .quick-links {
    .links-heading {
      margin-bottom: 10px;
      position: relative;
      padding-top: 25px;
      font-weight: 500;
      color: #4a4948;

      &::before {
        content: "";
        background-color: #29a7df;
        width: 70px;
        height: 4px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    a {
      font-weight: normal;
    }
    
  }

  .copyright {
    border-top: 2px solid #c5c5c5;
  }
}

.engineer-component {
  background-image: none;

  img {
    object-position: top right;
  }

  &::before,
  &::after {
    display: none;
  }
}

.proactively-engineered-solutions {
  .section-heading {
    &::before {
      background-color: #29a7df;
    }
  }

  &.e2e-capablities .content {
    justify-content: flex-start;
    background-color: #fff;
    color: #131313;

    &::after {
      display: none;
    }

    h4 {
      text-transform: none;
    }

    a {
      text-decoration: none;
    }
  }
}

.custom-list {
  padding-left: 2px;
  margin-top: 0;

  li {
    position: relative;
    align-items: flex-start;
    padding-left: 25px;
    list-style: none;

    >div {
      margin: 0;

      span {
        font-size: 25px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      //background-color: #29a7df;
      background-color: var(--bullet-bg-color);
      width: 13px;
      height: 13px;
      margin-right: 20px;
      left: 0;
      top: 11px;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0px;
    }

    p {
      margin: 0;
    }
  }

  &.white-list {
    li {
      color: #fff;

      &::before {
        background-color: #fff;
      }
    }
  }
}

.news-and-resource .news-blog .btn-blue {
  min-width: 190px !important;
}

.vertical-integration {
  .custom-list li {
    color: #29a7df;

    >div {
      margin-top: 0;
    }
  }

  h4 {
    margin-bottom: 30px !important;
  }
}

.well-versed {
  background-image: url("assets/images/well-versed.jpg");
  background-size: cover;
  min-height: 900px;
  position: relative;

  &::before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  &::before {
    left: 0;
    background-image: url("assets/images/polygon1.png");
    background-position: bottom left;
  }

  &::after {
    right: 0;
    background-image: url("assets/images/polygon2.png");
    background-position: bottom right;
  }

  .section-heading::before {
    background-color: #ffffff;
  }

  .content {
    position: relative;

    &::after {
      content: "";
      background-image: url("assets/images/well-versedicon.png");
      position: absolute;
      top: 28px;
      right: 0;
      width: 95px;
      height: 95px;
      background-size: 100%;
      background-repeat: no-repeat;
    }

    h4 {
      margin-bottom: 30px !important;
    }
  }
}

.speed-and-innovation .section-heading:before {
  background-color: #29a7df !important;
}

.speed-and-innovation {
  .content {
    position: relative;
    left: -50px;
    background-color: #ffffff;
    top: 30px;
    min-height: calc(100% - 60px);
    box-sizing: border-box;
    box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.06);
    width: calc(100% + 50px);

    h4 {
      margin-bottom: 30px !important;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    max-height: 622px;
    min-height: 622px;
    object-position: 8%;
  }
}

.medical-balloons {
  min-height: 545px;
  background-image: none;

  &:before,
  &:after {
    display: none;
  }
}

.baloon-vertical-integration {
  background-image: url("assets/images/vertical-integrated.jpg");
  background-size: cover;
  position: relative;

  &::before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 340px;
  }

  &::before {
    left: 0;
    background-image: url("assets/images/left-design.png");
    background-position: bottom left;
  }

  &::after {
    right: 0;
    background-image: url("assets/images/right-design.png");
    background-position: bottom right;
  }

  .custom-list li {
    color: inherit;
  }
}

.engineered-advantage {
  background-image: url("assets/images/engineered-advantage.jpg");
  background-size: cover;
  position: relative;

  .section-heading::before {
    background-color: #fff;
  }

  .custom-list li {
    color: #fff;

    &::before {
      background-color: #fff;
    }
  }
}

.in-house {
  background-image: url("assets/images/testing_Lab.jpg");
  background-size: cover;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  &::after {
    right: 0;
    background-image: url("assets/images/inhouse-design.png");
    background-position: bottom right;
  }

  .custom-list li {
    color: inherit;
  }
}

.virtual-tour {
  background: radial-gradient(50% 174.41% at 27.99% 50%, #0054a6 0%, #003c7e 100%);

  .section-heading::before {
    background-color: #fff;
  }
}

.wide-balloon-catheters {
  background-image: url("assets/images/wide-balloon-catheters.jpg");
  background-size: cover;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 600px;
  }

  &::after {
    right: 0;
    background-image: url("assets/images/wide-balloon-catheters-design.png");
    background-position: bottom right;
  }

  .section-heading::before {
    background-color: #fff;
  }

  .custom-list li {
    color: #fff;

    &::before {
      background-color: #fff;
    }
  }
}

.fast-balloon {
  background-color: #fafafa;
  min-height: 535px;
  position: relative;

  img {
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.balloon-capabilities .section-heading::before {
  right: 0;
  margin: 0 auto;
}

.custom-table {
  max-width: 1000px;
  height: 100%;
  margin: 0 auto 12px;
  border-top: 4px solid #29a7df;
  border-bottom: 2px solid #4a4948;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #949494;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    p {
      margin: 0;
    }
  }

  th {
    padding: 25px;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    background-color: #fbfafa;
  }

  td {
    font-size: 17px;
    padding: 22px 25px;

    ul {
      margin: 0;

      li {
        white-space: nowrap;
        margin: 8px 0;
      }
    }
  }
}

.question-about-baloons {
  background-color: #29a7df;
  position: relative;
  margin-bottom: -162px;
  //margin-top: 45px;
  min-height: 988px;
  box-sizing: border-box;

  &::before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    //background-size: 35vw;
  }

  &::before {
    background-image: url("assets/images/question-design1.png");
    background-position: top right;
  }

  &::after {
    background-image: url("assets/images/question-design2.png");
    background-position: bottom left;
  }

  .link>div {
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);

      .box-link {
        color: #000;
        font-weight: 500;
      }
    }
  }
}



.get-in-touch-expert {
  background-color: #ff7e24;
  position: relative;
  // margin-bottom: -16px;
  // margin-bottom: -162px;
  //margin-top: 45px;
  min-height: 190px;
  box-sizing: border-box;
  }

  .link>div {
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);

      .box-link {
        color: #000;
        font-weight: 500;
      }
    }
  }




.capabilities {
  .section-heading {
    &::before {
      background-color: #29a7df;
    }
  }
}

.contact-us {
  min-height: 680px;
  background-image: none;

  &::before {
    background-image: url("assets/images/contact-design2.png");
    background-size: 410px;
    background-position: -110px 0px;
  }
}

.content-heading {
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 28px !important;

  &::before {
    content: "";
    background-color: #29a7df;
    width: 70px;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.contact-details {
  h5 {
    text-transform: uppercase;
    font-weight: 700;
  }

  p {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    img {
      margin-right: 20px;
    }
  }
}

.contact-frm {
  background-color: #f5f5f5;

  .marketing-email-box {
    width: 324px;
    padding: 2px 15px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

    label {
      display: flex;
      flex-direction: row-reverse;
      margin: 0;
      justify-content: space-between;
    }
  }
}

.contact-img {
  img {
    max-width: 100%;
    width: 100%;
  }
}

.footerlink {
  margin-top: 0 !important;
  margin-bottom: 2px !important;
}

.MuiAccordionSummary-expandIconWrapper svg {
  display: none;
}

.MuiAccordionSummary-expandIconWrapper::after {
  content: "";
  position: relative;
  background-image: url("assets/images/icons/arrow.svg");
  width: 9px;
  height: 11px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.MuiAccordionSummary-root {
  min-height: 48px !important;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(212deg) !important;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
}

.about-us {
  min-height: 820px;
  background: none;

  &::before,
  &::after {
    display: none;
  }
}

.more-about-us {
  background: radial-gradient(50% 50% at 50% 50%, #0054a6 0%, #003b7d 100%);
  position: relative;

  .section-heading {
    &::before {
      background-color: #fff;
    }
  }

  &::before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    opacity: 0.3;
  }

  &::before {
    background-image: url("assets/images/polygon3.png");
    background-position: top left;
  }

  &::after {
    background-image: url("assets/images/polygon4.png");
    background-position: bottom right;
  }
}

.leadership {
  min-height: 530px;
  background: linear-gradient(89.97deg, #29a7df 19.61%, rgba(41, 167, 223, 0) 64.22%);

  &::before,
  &:after {
    display: none;
  }

  img {
    right: 0;
    width: auto;
  }
}

.page-links {
  margin-top: 40px;

  a {
    background-color: #fff;
    color: #29a7df;
    padding: 16px 30px;
    text-decoration: none;
    font-weight: 500;
    font-size: 36px;
    display: block;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
      color: #013d7f;
    }
  }
}

.focus-vision {
  .content::after {
    background-image: url("assets/images/bsp-icon.png");
  }

  .blue-bar {
    background: linear-gradient(270deg, #2aa7df 0%, #2a8cc6 54.01%, #2c65ba 100%);

    &::after {
      display: none;
    }
  }
}

.living-our-values {
  .section-heading::before {
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .flip-card {
    background-color: transparent;
    width: 320px;
    height: 320px;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front,
  .flip-card-back {
    padding: 20px;
    box-sizing: border-box;
  }

  .flip-card-front {
    background-color: #f2f2f2;
    color: #000;

    .content {
      padding-top: 25px;
    }
  }

  .flip-card-back {
    background-color: #29a7df;
    color: white;
    transform: rotateY(180deg);
  }

  .flip-box {
    li {
      display: block;
      padding: 10px;
    }
  }

  .flip-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .content {
      .icon-box {
        width: 94px;
        height: 94px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(64, 180, 229, 0.2);
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;

        img {
          max-width: 100%;
        }
      }

      h4 {
        font-size: 25px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 18px;
      }

      p {
        font-size: 20px;
        line-height: 1.25;
      }
    }
  }
}

.medical-device-list {
  padding-left: 20px !important;

  li {
    &::before {
      top: 18px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
    }

    a {
      color: #29a7df;
      text-decoration-color: #29a7df;
      font-size: 25px;
    }
  }
}

.footer-submenu {
  margin-left: 22px !important;
}

.level-two-banner {
  min-height: 545px;
  background: none;

  &:before,
  &:after {
    display: none;
  }
}

.level-four-banner {
  min-height: 275px !important;
  background: none;

  &:before,
  &:after {
    display: none;
  }
}

.mobile-menu {
  .MuiPaper-root {
    background: #29a6de url("assets/images/mobile-menu-bg.png");
    width: 100%;
    padding-top: 56px;
    background-repeat: no-repeat;
    background-position: right bottom;
    box-sizing: border-box;

    ul {
      padding: 15px 0 0 0;
      overflow: auto;
      max-height: 445px;

      li {
        //  padding-top: 5px;
        //padding-bottom: 5px;

        .MuiListItemText-root {
          margin-top: 5px !important;
        }

        a {
          color: #fff;
          font-weight: 500 !important;
        }

      }
    }
  }
}

.mobile-link {
  span {
    font-weight: 700;
  }
}

.mobile-submenu {
  span {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 1;
    //padding-top: -20px !important;
  }

}

.img-wrapper {
  padding-top: 0 !important;

  >div {
    box-sizing: border-box !important;
  }
}

.sub-nav {
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    height: 60px;
    align-items: center;
    flex-wrap: wrap;

    li {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      width: auto;
      flex-grow: 1;
      position: relative;

      &.active {
        a::before {
          opacity: 1;
        }
      }

      a {
        color: #000;
        font-size: 16px;
        text-transform: uppercase;
        text-decoration: none;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          width: 100%;
          height: 4px;
          background-color: #29a7df;
          opacity: 0;
          margin: 0 auto;
        }
      }
    }
  }

  &.top {
    background-color: #f8f8f8;
  }

  &.bottom {
    background-color: #29a7df;

    a {
      color: #fff;
    }
  }
}

.team-section {
  position: relative;

  .MuiPaper-root {
    border-radius: 0;
    box-shadow: none;
    position: relative;
    overflow: inherit;
  }

  .MuiCardMedia-media {
    height: 374px;
    object-fit: cover;
  }

  .MuiCardContent-root {
    padding-top: 14px;

    h5 {
      color: #29a7df;
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    p {
      font-size: 30px;
      font-style: italic;
      font-weight: 300;
      line-height: 34px;
    }
  }

  .selected {
    z-index: 9;

    .MuiCardContent-root {
      background-color: #29a7df;

      h5,
      p {
        color: #fff !important;
      }
    }

    .team-details-popover {
      display: block;
    }

    .MuiPaper-root {
      z-index: 1;
    }

    .team-overlay {
      display: block;
    }
  }

  .team-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
    display: none;
  }

  .team-details-popover {
    position: absolute;
    top: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    padding: 57px 66px 39px 41px;
    width: 790px;
    // width: 625px;
    box-sizing: border-box;
    display: none;

    p {
      line-height: 36px;
      font-size: 19px;
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn-close {
      position: absolute;
      top: 12px;
      right: 12px;

      svg {
        font-size: 35px;
        color: #000;
      }
    }
  }

  .MuiGrid-container {
    .MuiGrid-grid-xs-12:nth-child(3n + 3) {
      .team-details-popover {
        right: 0;
      }
    }
  }
}

.pagination {
  ul {
    display: flex;
    list-style: none;

    li {
      margin: 0 10px;
div{
  display: flex;
  align-items: center;
  justify-content: center;
}
      button {
        background-color: transparent;
        border: none;
        width: 64px;
        height: 64px;
        color: #333333;
        font-size: 15px;
        cursor: pointer;
        outline: none !important;
      }

      &.active {
        button {
          background-color: #29a7df;
          color: #ffffff;
        }
      }

      &.disabled {
        button {
          color: #69798a;
        }
      }
    }
  }
}

.pagination {
  .Mui-selected {
    background-color: #29a7df !important;
    color: #fff !important;
  }

  .MuiPaginationItem-root {
    min-width: 64px;
    height: 64px;
    font-size: 15px;
    border-radius: 0 !important;
  }
}

.pagination ul li button svg {
  display: none;
}

.pagination ul li:first-child button:before {
  content: "Prev";
}

.pagination ul li:last-child button:before {
  content: "Next";
}

.subscribe {
  background-color: #29a7df;
  min-height: 464px;
  padding: 105px 0;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  &::before,
  &:after {
    content: "";
    position: absolute;
    top: auto;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  &::before {
    left: 0;
    background-image: url("assets/images/molecule.png");
    background-position: bottom left;
    bottom: -80px;
  }

  &::after {
    right: 0;
    background-image: url("assets/images/molecule-1.png");
    background-position: bottom right;
    top: -110px;
  }

  .btn-subscribe {
    color: #29a7df !important;
  }
}

.blog {
  .news-and-resource {
    img {
      max-width: 100% !important;
      // width: 651px;
      width: 725px;
      // max-width: 856px !important;
    }

    .news-box {
      margin-left: -50px;
      // overflow: auto;
      padding-top: 1px;
      margin-bottom: 60px;
    }
  }

  .blog-box {
    .MuiCardMedia-media {
      object-position: top;
      object-fit: contain;
    }

    h5 {
      min-height: 74px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  a {
    color: #29a7df;
  }
}

.search-blog {
  h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 74px;
  }
}

.global-search-section-heading {
  position: relative;
  padding-top: 30px;
  font-size: 36px !important;
  margin-bottom: 15px !important;

  &::before {
    content: "";
    background-color: #29a7df;
    //background-color: var(--bullet-bg-color);
    width: 70px;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.blog-detail {
  >div {
    align-items: flex-end;

    img {
      // height: 615px;
      height: 615px;
      object-fit: contain;
      object-position: center;
      width: 856px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .news-box {
      margin-bottom: 50px;
      height: 403px;
      min-width: 699px;
      // overflow: hidden !important;


    }
  }

  .news-grid {
    display: grid;
    grid-gap: 20px;
    align-items: stretch;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  }

  .news-grid img {
    max-width: 100%;
    height: auto !important;
  }

  .pagination {
    max-width: 498px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px auto;

    button {
      color: #29a7df;
      font-weight: normal !important;
      text-transform: capitalize !important;
      font-size: 15px;
    }
  }
}

.react-responsive-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .react-responsive-modal-modal {
    padding: 0;
    width: 1000px;
    max-width: 95%;
    min-height: 700px;
    overflow: visible;

    >div {
      position: absolute;
    }
  }

  .react-responsive-modal-closeButton {
    top: -5px;
    right: -37px;

    svg {
      fill: #fff;
    }
  }
}

.react-responsive-modal-root {
  z-index: 9999 !important;
}

.blog-banner {
  min-height: 680px;
  background-image: none;

  &::after,
  &::before {
    display: none;
  }
}

.search-frm {
  label {
    font-weight: 500 !important;
    color: #27245e;
  }

  .MuiFilledInput-root {
    background-color: #ebebeb;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    &::before,
    &:after {
      border: none !important;
    }
  }

  .filter-dropdown {
    .Mui-focused {
      color: #fff !important;
    }
  }

  .MuiFilledInput-input {
    padding-right: 50px;
  }

  .Mui-focused {
    color: #000 !important;

    .MuiSelect-filled {
      background-color: #26255d;
    }
  }

  .search-icon {
    top: 15px;

    svg {
      width: 23px;
    }
  }
}

.search-result {
  min-height: 554px;

  &::after,
  &::before {
    display: none;
  }

  .search-frm {
    .MuiFilledInput-root {
      background-color: #fff;
    }
  }
}

.question-icon {
  background-color: #dfdfdf;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.btn-rfq {
  text-decoration: none;
  padding: 19px 24px;
  font-weight: 700;
  display: inline-block;
}

.rfq-frm {
  .content-heading::before {
    margin: 0 auto;
    right: 0;
  }
}

.frm-select {
  fieldset {
    border: none;
  }

  svg {
    color: #29a6de;
    transform: rotate(-90deg);
  }
}

.select-placeholder {
  color: #a2a2a2;
}

.result-box {
  *:not(a) {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  a:not(.MuiTypography-root) {
    color: rgb(122, 122, 122) !important;
    text-decoration: none !important;
    pointer-events: none;
  }

  p {
    margin-bottom: 20px;
  }
}

.MuiFormHelperText-root {
  color: #d90000 !important;
}

.rfq-frm-box,
.contact-frm {
  .MuiSelect-select {
    padding: 10px 14px !important;
  }
}

.mobile-number {
  .MuiFormControl-root {
    margin-bottom: 0;

    .MuiInput-root {
      width: "100%";
      border-radius: 0;
      position: relative;
      background-color: #fff;
      font-size: 16px;
      padding: 6.5px 14px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.certifications {
  .pagination {
    display: none;
  }
}

.privacy-policy {
  margin-bottom: 150px;

  .privacy-policy-section {
    margin-bottom: 40px;

    h4 {
      margin-bottom: 10px;
      font-size: 21px;
    }

    &:first-child {
      h4 {
        font-size: 32px;
        color: #19d;
      }
    }
  }
}

.search-icon {
  cursor: pointer;

  &:hover svg {
    color: #19d;
  }
}

.blog-section{
 display: flex;
 gap: 20px;
}
.page-not-found-wrapper{
  background-color:#29a6de ;
  position: relative;
  height: 600px;
  width: 100%;
  .page-not-found-bg{

    img{
      width: 70%;
      position: absolute;
      bottom: -50px;
      left: 0;
    }
  }
  .page-content{
    .page-not-found-btn{
      &:hover {
        background-color: #fff;
      }
    }
  }
  .page-not-found-bg-right{
  
     img{
      width: 40%;
      position: absolute;
      top: 0;
       right: 0;
     }
  }
}
.common-creative-space{
  margin-top: -140px;
}

@media screen and (max-width: 1535px) {
.section-heading-expert{
  margin-left: 0 !important;
  text-align: center !important;
  margin-bottom: 30px !important;
}
.btn-orange[class] {
  margin-right: 0 !important;
}

.quadra-btn-yellow[class] {
  width: auto !important; 
}


}

@media screen and (min-width: 1200px) {
  .footer-links-wrapper{
    flex-wrap: nowrap !important;
  }
    .quadra-btn-yellow[class] {
    width: auto !important; 
  }
}


@media screen and (min-width: 1024px) {
  .level-four-banner {
    min-height: 360px !important;
  }
  
}

@media screen and (min-width: 1300px) {
  header {
    min-height: 150px;
  }
}


@media screen and (max-width: 1800px) {
  .MuiContainer-root {
    padding-left: 92px !important;
    padding-right: 92px !important;
  }

  .blog {
    .blog-detail {
      >div {
        img {
          width: 65%;
        }

        .news-box {
          min-width: auto;
          max-width: 90%;
        }
      }
    }
  }

  // .main-navs>li{
  //   margin-left: 40px !important;
  //   span {
  //     font-size: 17px;
  //   }
  // }

}
@media screen and (max-width: 1600px) {
.MuiContainer-root {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
// .footer .MuiGrid-container .MuiGrid-item{
//   flex-basis: 13.6667% !important;
//   }
.quadra-btn-yellow[class] {
  width: auto !important; 
}
}


@media screen and (max-width: 1400px) {
  .news-and-resource img {
    max-width: 60%;
  }

  .more-about-us {

    &::before,
    &:after {
      background-size: 500px;
    }
  }

  .text-section {
    padding-left: 24px !important;
  }

  .revers .text-section {
    padding-right: 24px !important;
  }
  .logo img {
    min-width: 220px;
    width: 320px;
}

}

@media screen and (max-width: 1300px) {
  .main-navs {
    >li {
      span {
        font-size: 14px;
        margin-left:2px;
      }
    }
  }

  .bullet-indicators {
    button {
      width: 150px;
    }
  }

  .footer {
    margin: -50px 0 0;
  }

  .header-navlinks {
    input {
      width: 240px !important;
    }

    a {
      font-size: 14px;
    }
  }

  .more-about-us {

    &::before,
    &:after {
      background-size: 400px;
    }
  }

  .blog {
    .blog-detail {
      >div {
        .news-box {
          h1 {
            font-size: 30px;
          }
        }
      }
    }
  }

  .quadra-btn-yellow[class] {
    width: auto !important; 
  }
  // .footer .MuiGrid-container .MuiGrid-item{
  //   flex-basis: 13.6667% !important;
  //   }
}

@media screen and (max-width: 1199px) {

  // .hero-section {
  //   padding-top: 80px;
  //   &::before,
  //   &:after {
  //     background-size: 400px;
  //   }
  // }
  .more-about-us {

    &::before,
    &:after {
      background-size: 300px;
    }
  }

  .speed-and-innovation {
    .content {
      left: 0px;
      background-color: #ffffff;
      top: 0px;
      min-height: auto;
      box-sizing: border-box;
      box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.06);
      width: 100%;
      padding: 48px 15px 15px;
    }
  }

  .text-section {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .revers .text-section {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .subscribe {
    &::before {
      background-size: 300px;
      bottom: -50px;
    }

    &::after {
      top: -280px;
      background-size: 200px;
    }
  }

  .e2e-capablities {
    .MuiGrid-container {
      .white-box {
        top: -10px;
        width: calc(100% + 20px);
        left: -10px;
      }
    }
  }

  .MuiContainer-root {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

}

@media screen and (max-width: 1024px) {
  .page-not-found-wrapper .page-not-found-bg img{
    bottom: -10px;
  }
  .blog-section{
    flex-direction: column;
   }
  .hero-section {
    padding-top: 30px;
    // background-image: linear-gradient(180deg, #6c6d72 34.29%, rgba(41, 167, 223, 0) 45.83%);
    background-image: linear-gradient(180deg, #6c6d72 59.29%, rgba(41, 167, 223, 0) 69.83%);
    min-height: 800px;

    img {
      height: 330px;
    }

    &::before {
      display: none;
    }

    &:after {
      background-size: 250px;
    }

    h3 {
      font-size: 50px;
    }

    &.level-four-banner {
      background-image: none;
    }
  }

  .bullet-indicators {
    bottom: 80px;
  }

  .e2e-capablities {
    .content {
      padding-right: 24px;

      h4 {
        font-size: 30px;
        padding-right: 70px;
      }

      &::after {
        width: 50px;
        height: 50px;
      }
    }

    .blue-bar {
      height: 12px;
      width: 100%;
    }
  }

  .creative-solutions {
    min-height: 400px;
    height: auto;
    background-position: right;

    h4 {
      font-size: 24px;
    }

    p {
      font-size: 18px;
    }
  }

  .header-navlinks ul {
    margin: 15px 0 !important;
  }

  body {
    padding-top: 105px;
    overflow-x: hidden;
  }

  .news-blog {
    h4 {
      max-width: 100%;
    }
  }

  .about-us,
  .blog-banner {
    background-image: linear-gradient(180deg, #29a7df 36.5%, rgba(41, 167, 223, 0) 0);

    h3 {
      font-size: 38px !important;
    }

    img {
      object-fit: cover;
      object-position: top;
    }
  }

  .leadership {
    background-image: linear-gradient(180deg, #29a7df 45.5%, rgba(41, 167, 223, 0) 0%);
    padding-top: 30px !important;

    img {
      height: 330px;
      width: 100%;
      object-fit: cover;
    }
  }

  .page-links {
    a {
      padding: 12px 20px;
      font-size: 20px;
    }
  }

  .level-two-banner {
    background-image: linear-gradient(180deg, #29a7df 46%, rgba(41, 167, 223, 0) 0%);

    img {
      height: 325px;
    }
  }

  .engineer-component,
  .contact-us,
  .blog-banner {
    background-image: linear-gradient(180deg, #29a7df 28.29%, rgba(41, 167, 223, 0) 0%);
    min-height: 600px !important;

    img {
      height: 430px;
    }
  }

  .img-wrapper[class] {
    >div {
      padding: 0 !important;
    }
  }

  .sub-nav.top {
    display: none;
  }

  .sub-nav ul {
    padding: 20px 0 !important;
    height: auto;

    li {
      font-size: 18px;
      margin-right: 0;
      width: 30%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .video-wrapper[class] {
    padding: 0 !important;
  }

  .search-result {
    min-height: 380px;
    background-image: none;

    img {
      height: 100%;
    }

    &::after,
    &::before {
      display: none;
    }

    .search-frm {
      .MuiFilledInput-root {
        background-color: #fff;
      }
    }
  }

  .level-four-banner {
    min-height: 200px !important;

    img {
      height: 200px;
    }
  }
  .quadra-btn-yellow[class] {
    width: auto !important; 
  }

  // .logo::before {
  //   height: 120px;
  //   }

}



@media screen and (max-width: 899px) {
  body {
    padding-top: 64px;
    overflow-x: hidden;
  }

  .bullet-indicators {
    button {
      width: 14vw;
      height: 6px;
    }
  }

  .e2e-capablities {
    top: -20px;
  }
  .common-creative-space {
    margin-top: -50px;
}
  .creating-value {
    background-size: contain;

    .creating-value-box {
      h4 {
        font-size: 24px;
      }

      p {
        padding-right: 0px;
      }
    }
  }

  .news-and-resource {
    img {
      max-width: 100%;
      width: auto;
    }

    .news-box {
      height: auto;
      width: 93%;
      margin-top: -40px;

      h1 {
        font-size: 36px;
      }
    }
  }

  .medical-balloons {
    background-image: none;
  }

  .question-about-baloons {
    &::before {
      background-size: 300px;
    }
  }

  .card-grid {
    max-height: 100% !important;
    margin-left: 0 !important;
    width: 100% !important;

    .MuiAccordionSummary-content {
      margin: 0 !important;

      h1 {
        font-size: 22px;
        color: #29a7df;
        margin-bottom: 0 !important;
      }
    }
  }

  .e2e-capablities.building-strategic .content {
    width: 100%;
  }

  .logo img {
    width: 180px;
  }

  // .logo::before {
  //   height: 110px;
  //   }
   

  .bullet-indicators {
    bottom: 48px;
  }

  .creating-value {
    .MuiPaper-root {
      border-radius: 0 !important;
    }

    .MuiAccordionDetails-root {
      background-color: #fff;
    }
  }

  .proactively-engineered-solutions {
    &.e2e-capablities .content {
      padding: 48px 15px 15px;

      h4 {
        padding-right: 0;
      }
    }
  }

  .team-section .team-details-popover {
    max-width: 100%;
  }

  .blog .news-and-resource .news-box {
    margin-left: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  // .news-box {
  //   padding-top: 1px;
  //   margin-bottom: 60px;
  // }
  .logo img {
    top: 0px;
  }

  
  .blog .blog-detail>div {
    img {
      width: 100%;
      height: auto;
    }

    .news-box {
      height: auto;
    }
  }

  .blog {
    padding-top: 0 !important;
  }
}

@media screen and (max-width: 767px) {

  .section p,
  .speed-and-innovation p,
  .e2e-capablities .content p,
  .living-our-values p,
  .connecting-frm p,
  .rfq-frm-box p,
  .rfq-frm p,
  .blog p {
    font-size: 16px !important;
    
  }

  body {
    overflow-x: hidden;
  }

  .e2e-capablities .content::after {
    top: 8px;
    right: 8px;
    width: 53px;
    height: 53px;
  }

  .e2e-capablities .content h4 * {
    font-size: 26px;
    line-height: 35px;
  }

  .contact-frm .marketing-email-box {
    max-width: 264px;
  }

  .medical-device-list li {
    padding-top: 2px;

    a {
      font-size: 16px !important;
    }
  }

  .speed-and-innovation img {
    min-height: 385px;
  }
  .page-not-found-wrapper .page-not-found-bg img {
    bottom: 0;
  }
  .custom-list li {
    padding-left: 16px;

    &::before {
      top: 8px;
      width: 9px;
      height: 9px;
    }

    >div span {
      font-size: 16px;
    }

    ul li {
      font-size: 16px;
    }
  }

  .custom-table {
    margin: 0 12px;

    td {
      font-size: 15px;
      padding: 22px 0;
    }
  }

  .section-heading,
  .content-heading {
    font-size: 29px !important;
  }

  .hero-section {
    padding-top: 12px !important;

    h1 {
      font-size: 28px !important;
    }

    p {
      font-size: 16px;
    }
  }

  .level-two-banner,
  .about-us {
    min-height: 330px;

    img {
      height: 210px;
    }
  }

  .level-two-banner {
    background-image: linear-gradient(180deg, #29a7df 36.5%, rgba(41, 167, 223, 0) 0%);
  }

  .engineer-component,
  .contact-us,
  .blog-banner {
    background-image: linear-gradient(180deg, #29a7df 36.29%, rgba(41, 167, 223, 0) 0%);
    min-height: 330px !important;

    img {
      height: 210px;
    }
  }

  .leadership {
    background-image: linear-gradient(180deg, #29a7df 42%, rgba(41, 167, 223, 0) 0%);
    min-height: 400px;

    img {
      height: 235px;
      width: 100%;
    }
  }

  .creating-value {
    h6 {
      font-size: 18px;
      font-weight: 400;
    }

    .section-heading {
      font-size: 26px !important;
    }
  }

  .capabilities p {
    font-size: 16px;
  }

  .card .btn-link {
    font-size: 18px;
  }

  .table-responsive {

    p,
    td {
      font-size: 16px !important;
    }
  }

  .creative-solutions p {
    font-size: 16px;
    font-weight: 700;
  }

  .news-and-resource .news-blog .btn-blue {
    min-width: 150px !important;
  }

  .news-and-resource .news-blog h4 {
    max-width: 230px;
  }

  .btn-orange[class] {
  font-size: 20px !important;  
  padding: 18px 20px !important;
  }
}


.mail-msg {

  // margin-top: 24px;
  // margin-left: 113px;
  // height: 0px;
  color: #29A7DF;
}

@media screen and (max-width: 600px) {
  body {
    padding-top: 56px;
    overflow-x: hidden;
  }

  .hero-section.home h1 {
    font-size: 42px !important;
  }

  .e2e-capablities .content h4 {
    font-size: 24px;
  }

  .MuiContainer-root {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .news-and-resource {
    .MuiButton-root {
      height: 32px;
    }

    .MuiListItem-root {
      h1 {
        font-size: 20px;
        overflow: hidden;
      }

      .MuiListItemAvatar-root {
        max-width: 143px;
        margin-right: 20px;
        min-width: 143px;
      }
    }

    .news-box {
      h4 {
        font-size: 27px;
      }
    }
  }

  .sub-nav ul {
    li {
      width: 50%;
    }
  }

  .creating-value .section-heading {
    max-width: 240px;
  }

  // .logo::before {
  //   height: 100px;
  //   }


}

@media screen and (max-width: 575px) {
  .sub-nav ul {
    li {
      width: 100%;
    }
  }
  .page-not-found-wrapper {
    .page-content{
      h1{
        font-size: 36vw;
      }
      h2{
        font-size: 10vw;
      }
      p{
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .about-us {
    padding-top: 9px !important;
  }
  .section-heading-expert{
    font-size: 22px !important;
  }
 .btn-orange[class] {
 font-size: 16px !important;  
 padding: 16px 16px !important;
 }
}